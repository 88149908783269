interface ValidateMinLength {
  (minLength: number, value?: string, errorText?: string): string | undefined;
}

interface ValidateCharactersInString {
  (value: string, errorText?: string): string | undefined;
}

export const validateMinLength: ValidateMinLength = (minLength, value, errorText) => {
  if (!value || value.length < minLength) {
    return errorText || 'Не менее 8 знаков';
  }
  return undefined;
};

export const validateNumbersInString: ValidateCharactersInString = (value, errorText) => {
  if (/\d/.test(value)) {
    return undefined;
  } else {
    return errorText;
  }
};

export const validateLatinCharactersInString: ValidateCharactersInString = (value, errorText) => {
  if (/[a-zA-Z]/.test(value)) {
    return undefined;
  } else {
    return errorText;
  }
};

export const validateCharactersInString: ValidateCharactersInString = (value, errorText) => {
  if (/[\p{L}]/u.test(value)) {
    return undefined;
  } else {
    return errorText;
  }
};

export const validateEmail = (value?: string, errorText?: string) => {
  if (!value) {
    return undefined;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailRegex.test(value)) {
    return undefined;
  } else {
    return errorText || 'Некорректный формат email адреса';
  }
};
