import { sendGTMEventOnce, sendGTMEvent } from '../events-factory';

export const sendHeaderRegistrationButtonEvent = () => {
  sendGTMEvent({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_header_registration_button'
  });
};

export const sendRegistrationFormInputEvent = (registrationType: string) => {
  sendGTMEventOnce({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_form_field_input',
    registration_type: registrationType
  });
};

export const sendRegistrationPasswordInputEvent = (registrationType: string) => {
  sendGTMEventOnce({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_form_field_password',
    registration_type: registrationType
  });
};

export const sendRegistrationAgreementClickEvent = (enabled: boolean) => {
  sendGTMEvent({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_form_field_isplayeragree',
    enabled: enabled
  });
};

export const sendRegistrationContractInfoClickEvent = () => {
  sendGTMEvent({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_contract_info'
  });
};

export const sendRegistrationSuccessEvent = (userId: string) => {
  sendGTMEvent({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_success',
    user_id: userId
  });
};

export const sendRegistrationFailEvent = (errorText: string) => {
  sendGTMEvent({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_error',
    error_text: errorText
  });
};

export const sendRegistrationFormButtonSubmitEvent = (registrationType: string) => {
  sendGTMEvent({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_form_button_submit',
    registration_type: registrationType
  });
};

export const sendRegistrationFormCodeFieldEvent = (registrationType: string) => {
  sendGTMEventOnce({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_form_field_code',
    registration_type: registrationType
  });
};

export const sendRegistrationFormResendButtonEvent = (registrationType: string) => {
  sendGTMEvent({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_form_resend_button',
    registration_type: registrationType
  });
};

export const sendRegistrationFormCodeConfirmEvent = (registrationType: string) => {
  sendGTMEvent({
    event: 'registration',
    eventCategory: 'registration',
    eventAction: 'registration_form_code_confirm',
    registration_type: registrationType
  });
};
