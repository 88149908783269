import { FC, useState } from 'react';
import { useScopedI18n } from '@/shared/locales/client';
import { Button, TextInput, Title } from '@/shared/ui';
import { Controller, useForm } from 'react-hook-form';
import { validateEmail } from '@/shared/lib/text-vallidators';
import { LIMIT_EXCEEDED } from '../lib/consts';

export type EmailValidationFormValues = {
  email: string;
};

type Props = {
  handleSubmitForm: (
    data: EmailValidationFormValues
  ) => Promise<{ success: boolean; message?: string }>;
  handleBack?: () => void;
  email?: string;
};

const EmailValidationForm: FC<Props> = ({ handleSubmitForm, handleBack, email }) => {
  const t = useScopedI18n('validation');

  const defaultValues = {
    email: email || ''
  };

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<EmailValidationFormValues>({
    defaultValues: defaultValues,
    mode: 'onTouched'
  });

  const [error, setError] = useState<string | null>(null);
  const clearError = () => setError(null);

  const handleSubmitWithError = async (data: EmailValidationFormValues) => {
    const res = await handleSubmitForm(data);
    if (!res.success && res.message) {
      setError(res.message);
    }
  };

  const onSubmit = (data: EmailValidationFormValues) => {
    void handleSubmitWithError(data);
  };

  const onChangeWithClearError = (onChange: (value: unknown) => void) => (value: unknown) => {
    clearError();
    onChange(value);
  };

  //TODO DAN-932: поменять на ключ когда на бэке завезут ключи
  const submitButtonDisabled = !isValid || (!!error && error !== LIMIT_EXCEEDED);

  return (
    <div className="w-full md:w-58t">
      <form className="w-full">
        <Controller
          name="email"
          rules={{
            required: t('errors.required'),
            validate: (value) => validateEmail(value, t('errors.email'))
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              label={t('fields.traits.email')}
              name={field.name}
              onChange={onChangeWithClearError(field.onChange)}
              value={field.value}
              errorMessage={error?.message}
              onBlur={field.onBlur}
              disabled={!!email}
              placeholder={t('fields.email.placeholder')}
            />
          )}
        />
        <div className="mt-[8px] w-full md:mt-5">
          {error && (
            <div className="mb-2 w-full rounded-md bg-yellow px-2 py-1t text-base text-black md:mb-2t md:px-2t md:py-[14px] md:text-lg">
              {error}
            </div>
          )}
          <p className="typography-mobile-system-info my-1">{t('emailConfirmationSubtitle')}</p>
          <Button
            name="submit"
            type="submit"
            size="lg"
            className="w-full"
            onClick={handleSubmit(onSubmit)}
            disabled={submitButtonDisabled}
          >
            {t('fields.submit')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EmailValidationForm;
