import { FC, useState } from 'react';
import { useScopedI18n } from '@/shared/locales/client';
import { Button, Title } from '@/shared/ui';
import { Controller, useForm } from 'react-hook-form';
import { CustomPhoneInput } from '@/shared/ui/new-phone-input';
import { LIMIT_EXCEEDED } from '../lib/consts';
import { countries, detectCountryByNumber } from '@/shared/ui/new-phone-input/countries';

export type PhoneValidationFormValues = {
  phone: string;
};

type Props = {
  handleSubmitForm: (
    data: PhoneValidationFormValues
  ) => Promise<{ success: boolean; message?: string }>;
  handleBack?: () => void;
  phone?: string;
};

const PhoneValidationForm: FC<Props> = ({ handleSubmitForm, phone }) => {
  const t = useScopedI18n('validation');

  const defaultValues = {
    phone: phone || ''
  };

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<PhoneValidationFormValues>({
    defaultValues: defaultValues,
    mode: 'onTouched'
  });

  const [error, setError] = useState<string | null>(null);
  const clearError = () => setError(null);

  const handleSubmitWithError = async (data: PhoneValidationFormValues) => {
    const res = await handleSubmitForm(data);
    if (!res.success && res.message) {
      setError(res.message);
    }
  };

  const onSubmit = (data: PhoneValidationFormValues) => {
    void handleSubmitWithError({
      ...data,
      phone: data.phone?.replace(/\D/g, '')
    });
  };

  const onChangeWithClearError = (onChange: (value: unknown) => void) => (value: unknown) => {
    clearError();
    onChange(value);
  };

  //TODO DAN-932: поменять на ключ когда на бэке завезут ключи
  const submitButtonDisabled = !isValid || (!!error && error !== LIMIT_EXCEEDED);

  return (
    <div className="w-full rounded-md">
      <form className="w-full">
        <Controller
          name="phone"
          control={control}
          rules={{
            required: t('errors.required'),
            pattern: { value: /^[^_]+$/, message: t('errors.phone') },
            validate: (value) =>
              detectCountryByNumber(value).iso === countries[0].iso ? t('errors.phone') : true
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomPhoneInput
              value={field.value}
              onChange={onChangeWithClearError(field.onChange)}
              name={field.name}
              errorMessage={error?.message}
              label={t('fields.traits.phone')}
              infoText={t('fields.willSendCode')}
              onBlur={field.onBlur}
              disabled={!!phone}
            />
          )}
        />
        <div className="mt-4 w-full md:mt-5">
          {error && (
            <div className="mb-2 w-full rounded-md bg-yellow px-2 py-1t text-base text-black md:mb-2t md:px-2t md:py-[14px] md:text-lg">
              {error}
            </div>
          )}
          <Button
            name="submit"
            type="submit"
            size="lg"
            className="h-6 w-full"
            onClick={handleSubmit(onSubmit)}
            disabled={submitButtonDisabled}
          >
            {t('fields.submit')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PhoneValidationForm;
