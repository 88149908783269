import { gql } from '@apollo/client';

export const sendEmailConfirmation = gql`
  mutation EmailCodeConfirmation($email: String!) {
    emailCodeConfirmation(email: $email) {
      success
      message
      error {
        message
      }
    }
  }
`;

export const sendPhoneConfirmation = gql`
  mutation PhoneCodeConfirmation($phone: String!) {
    phoneCodeConfirmation(phone: $phone) {
      success
      message
      error {
        message
      }
    }
  }
`;

export const completeEmailConfirmation = gql`
  mutation CompleteEmailConfirmation($email: String!, $code: String!) {
    completeEmailConfirmation(email: $email, code: $code) {
      success
      message
    }
  }
`;

export const completePhoneConfirmation = gql`
  mutation CompletePhoneConfirmation($phone: String!, $code: String!) {
    completePhoneConfirmation(phone: $phone, code: $code) {
      success
      message
    }
  }
`;
