import React, { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { RegistrationType, ValidationData } from '@/features/registration/lib/use-registration';
import { useResend } from '@/features/validation/lib/use-resend';
import { ValidationType } from '@/features/validation/lib/use-validation';
import { usePayment } from '@/features/wallet';
import { useScopedI18n } from '@/shared/locales/client';
import { ArrowLeftIcon, Button, TextInput } from '@/shared/ui';
import {
  sendRegistrationFormCodeConfirmEvent,
  sendRegistrationFormCodeFieldEvent,
  sendRegistrationFormResendButtonEvent,
} from '@/shared/lib/gtm/events/registration-events';
import { formatPhoneNumber } from '@/features/validation/lib/helper';

const VALIDATION_CODE_LENGTH = 4;

type Props = {
  wide?: boolean;
  disablePadding?: boolean;
  handleSubmit: (method: RegistrationType, code: string) => Promise<string | undefined>;
  sendConfirmationCode: (method: RegistrationType, source?: string) => Promise<void>;
  handleReturn: () => void;
  isUnconfirmedAllowed?: boolean;
  handleValidateUnconfirmed?: (
    method: ValidationType,
    validationData: ValidationData,
    code?: string,
  ) => Promise<string | undefined>;
  withSkip?: boolean;
  handleSkip?: () => void;
  phone?: string;
  email?: string;
};

const ValidationForm: FC<Props> = ({
                                     wide,
                                     disablePadding,
                                     handleSubmit,
                                     email,
                                     phone,
                                     sendConfirmationCode,
                                     handleReturn,
                                     isUnconfirmedAllowed,
                                     handleValidateUnconfirmed,
                                     withSkip,
                                     handleSkip,
                                   }) => {
  const [code, setCode] = useState(Array(VALIDATION_CODE_LENGTH).fill(''));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [validationError, setValidationError] = useState(false);
  const t = useScopedI18n('validation');
  const { formattedTime, sendCodeReady, resetTimer, isCalledMoreThanLimit } = useResend();
  const { openDeposit } = usePayment();

  const method = email ? 'email' : 'phone';

  const handleResend = async () => {
    if (!sendCodeReady) return;
    sendRegistrationFormResendButtonEvent(method);
    try {
      await sendConfirmationCode(method, email || phone);
      resetTimer();
      setCode(Array(VALIDATION_CODE_LENGTH).fill(''));
      setValidationError(false)
      inputRefs.current[0]?.focus();
    } catch (e) {
      console.log(e);
    }
  };

  const submit = async () => {
    const value = code.join('');
    sendRegistrationFormCodeConfirmEvent(method);
    if (isUnconfirmedAllowed && handleValidateUnconfirmed) {
      const data = phone ? { phone } : { email };
      const resp = await handleValidateUnconfirmed(method, data, value);
      if (resp) setValidationError(true);
      console.log(resp, '1')
      if (!resp) openDeposit();
      return;
    }
    const resp = await handleSubmit(method, value);
    console.log(resp, '2')
    if (!resp) openDeposit();
    if (resp) setValidationError(true);
  };

  const handleInputChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!/^[0-9]*$/.test(value)) return;

    setValidationError(false);
    const newCode = [...code];

    if (value.length > 1) {
      const digits = value.split('').slice(0, VALIDATION_CODE_LENGTH);
      digits.forEach((digit, i) => {
        if (i < VALIDATION_CODE_LENGTH) {
          newCode[i] = digit;
        }
      });
      setCode(newCode);

      const nextEmptyIndex = newCode.findIndex(digit => !digit);
      const focusIndex = nextEmptyIndex === -1 ? VALIDATION_CODE_LENGTH - 1 : nextEmptyIndex;
      inputRefs.current[focusIndex]?.focus();
    } else {
      newCode[index] = value;
      setCode(newCode);

      if (value !== '' && index < VALIDATION_CODE_LENGTH - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }

    sendRegistrationFormCodeFieldEvent(method);
  };

  const handlePaste = (index: number) => (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').replace(/\D/g, '');
    if (!pastedData) return;

    setValidationError(false);
    const newCode = [...code];
    const digits = pastedData.split('').slice(0, VALIDATION_CODE_LENGTH);

    digits.forEach((digit, i) => {
      if (i < VALIDATION_CODE_LENGTH) {
        newCode[i] = digit;
      }
    });

    setCode(newCode);
    const nextEmptyIndex = newCode.findIndex(digit => !digit);
    const focusIndex = nextEmptyIndex === -1 ? VALIDATION_CODE_LENGTH - 1 : nextEmptyIndex;
    inputRefs.current[focusIndex]?.focus();

    sendRegistrationFormCodeFieldEvent(method);
  };

  const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = '';
      setCode(newCode);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const isComplete = code.every(digit => digit !== '');

  const handleOpenSupport = () => {
    if (typeof window.zE !== 'undefined') {
      window.zE('messenger', 'open');
    }
  }

  return (
    <div>
      <div
        className={
          'relative flex flex-col items-center md:items-start md:hidden'
        }
      >
        <Button
          onClick={handleReturn}
          className={
            'absolute left-0 top-[1px] !m-0 !h-[12px] !w-[12px] !min-w-[12px] !p-0 md:hidden'
          }
          variant="empty"
        >
          <ArrowLeftIcon className={'text-text-brand '} />
        </Button>

        <h1
          className={
            'text-gray-900 mb-[22px] text-18 font-medium tracking-tight text-primary md:text-24 block text-18 font-bold leading-[20px] text-text-primary'
          }
        >
          {email ? t('emailConfirmationTitle') : t('phoneConfirmationTitle')}
        </h1>

      </div>
      <div
        className={classNames(wide ? 'w-full' : 'w-full rounded-md', 'min-w-[calc(100vw-32px)] rounded-s md:min-w-0 md:w-[490px] max-w-[756px] bg-background-card flex flex-col p-2 md:p-0')}>
        <div onClick={handleReturn}
             className={'flex cursor-pointer gap-[4px] mb-4 align-center text-text-primary text-16 md:block hidden leading-[20px]'}>
          <ArrowLeftIcon className={'text-text-primary mt-[-3px]'} /> {t('back')} </div>
        <h2
          className="mb-[24px] font-roboto text-[24px] font-bold leading-28 tracking-tight text-white md:text-24m md:block hidden">
          {email ? t('emailConfirmationTitle') : t('phoneConfirmationTitle')}
        </h2>
        <div
          className={classNames('rounded-b-md rounded-tr-md', {
            'px-2 py-4 md:p-5': !disablePadding,
          })}
        >
        <span className="text-base text-text-primary md:text-xl">
          {t('enterCode')} <br className={'md:hidden'} /> {phone ? formatPhoneNumber(phone) : email}
        </span>

          <div className="flex gap-[4px] md:gap-1 mt-1 md:mt-1t">
            {Array(VALIDATION_CODE_LENGTH).fill(null).map((_, index) => (
              <div key={index}>
                <TextInput
                  ref={(el: HTMLInputElement | null) => {
                    inputRefs.current[index] = el;
                  }}
                  className={'md:!min-h-[54px] !min-h-[40px]'}
                  hideCross
                  type="text"
                  maxLength={1}
                  value={code[index]}
                  placeholder={'_'}
                  onChange={handleInputChange(index)}
                  onKeyDown={handleKeyDown(index)}
                  onPaste={handlePaste(index)}
                  inputClassName="text-center md:text-xl bg-gray-700 rounded-lg !w-[38px] h-[40px] md:!w-[54px] md:!h-[54px] block text-[12px] leading-[40px] !p-0 !min-h-[40px]"
                  name={`code-${index}`}
                  hideErrorMessage
                  onInputStart={() => sendRegistrationFormCodeFieldEvent(method)}
                  errorMessage={validationError ? t('errors.validation') : undefined}
                  inputMode="numeric"
                />
              </div>
            ))}
          </div>
          <div className={'flex flex-col gap-[25px]'}>
          {sendCodeReady && (
            <div className="h-2 text-sm text-error md:mt-[4px]">
              {sendCodeReady ? t('errors.codeExpired') : ''}
            </div>
          )}
          {validationError && !sendCodeReady && (
            <div className="h-2 text-sm text-error  md:mt-[4px]">
              {validationError ? t('errors.validation') : ''}
            </div>
          )}
          </div>
          <div className={'flex gap-1 mt-[36px]  md:mt-[28px] text-14 leading-[16px]'}>
        <span onClick={handleResend}
              className={classNames('point block cursor-pointer text-base text-primary underline decoration-text-disabled', sendCodeReady ? 'text-primary !decoration-primary' : 'text-text-disabled')}>
          {t('resend')}
        </span>
            {!sendCodeReady && <span className={'text-text-primary '}>{t('resendWithSeconds', { seconds: formattedTime })}</span>}
          </div>
          {isCalledMoreThanLimit && <div onClick={handleOpenSupport} className={'cursor-pointer text-base text-primary underline mt-1'}>{t('contactSupport')}</div>}
          <Button
            onClick={submit}
            size="lg"
            disabled={!isComplete || validationError || sendCodeReady}
            className="w-full mt-2 md:text-[18px] md:leading-22 text-12 leading-16 md:font-semibold"
          >
            {t('next')}
          </Button>
          {isUnconfirmedAllowed && (
            <span
              onClick={withSkip ? handleSkip : handleReturn}
              className="mt-2 block cursor-pointer text-base text-primary underline decoration-primary md:mt-2t md:text-xl"
            >
            {withSkip ? t('skip') : t('back')}
          </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidationForm;
