import { useEffect, useState } from 'react';
import { useInterval } from '@/shared/lib/hooks/use-interval';

const TIMER = 180;
const MAX_CALLS_PER_DAY = 5;

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export const useResend = (timer: number = TIMER) => {
  const [seconds, setSeconds] = useState(timer);
  const [sendCodeReady, setSendCodeReady] = useState(false);
  const [isCalledMoreThanLimit, setIsCalledMoreThanLimit] = useState(false);

  const updateCallCount = () => {
    const today = new Date().toLocaleDateString();
    const storedData = localStorage.getItem('resendCalls');
    const callsData = storedData ? JSON.parse(storedData) : { date: today, count: 0 };

    if (callsData.date !== today) {
      callsData.date = today;
      callsData.count = 0;
    }

    callsData.count += 1;
    localStorage.setItem('resendCalls', JSON.stringify(callsData));

    if (callsData.count > MAX_CALLS_PER_DAY) {
      setIsCalledMoreThanLimit(true);
    }
  };

  useInterval(() => {
    setSeconds((prevSeconds) => {
      if (prevSeconds === 1) {
        setSendCodeReady(true);
        return 0;
      }
      return prevSeconds - 1;
    });
  }, 1000);

  const resetTimer = (newTimer: number = TIMER) => {
    setSeconds(newTimer);
    setSendCodeReady(false);
    updateCallCount();
  };

  return { formattedTime: formatTime(seconds), sendCodeReady, resetTimer, isCalledMoreThanLimit };
};